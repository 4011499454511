import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/HomeView.vue')
  },
  {
    path: '/concessionaria/:codigo',
    name: 'form',
    component: () => import( '../views/FormView.vue')
  },
  {
    path: '/contratos/:codigo',
    name: 'contratos',
    component: () => import( '../views/TabelaContratos.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
