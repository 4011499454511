import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Hotjar from '@hotjar/browser';

//hotjar
const siteId = 5123443;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

//Vue Sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

//Vue mask
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

//Vue signature pad
import VueSignaturePad from 'vue-signature-pad';

import router from './router'
Vue.use(VueSignaturePad);

Vue.config.productionTip = false


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
